import GameProvider from './GameProvider';

const maxAjaxAttempts = 3;

function ajaxResponseErrorHandling(response) {
	if(!response.ok) {
		throw Error(response);
	}

	try {
		return response.json();
	} catch(e) {
		if(process.env.NODE_ENV === 'development') {
			console.error('Error parsing JSON.', e);
		}
	}
}

function ajaxTextResponseErrorHandling(response) {
	if(!response.ok) {
		throw Error(response);
	}

	try {
		return response.text();
	} catch(e) {
		if(process.env.NODE_ENV === 'development') {
			console.error('Error parsing TEXT.', e);
		}
	}
}

/**
 * Gets the winning numbers for a specified game & date.
 *
 * @param game Game object from ./GameProvider
 * @param drawDate Date
 * @param jurisdictionOverride used to override the jurisdiction in case we need to make a BC call when on MB
 * @param attemptedTries
 * @returns {Promise<Response>}
 */
function getWinningNumbers(game, drawDate, jurisdictionOverride, attemptedTries = 0) {
	if(!game.drawsOnDate(drawDate)) {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const error = new Error('📅❌ Game "' + game.name + '" does not draw on ' + days[drawDate.getDay()] + ' (Date: ' + drawDate + '). Cannot request winning numbers');
		return Promise.reject(error);
	}

	const url = getWinningNumbersUrl(game, drawDate, jurisdictionOverride);

	return fetch(url)
		.then((response) => {
			return ajaxResponseErrorHandling(response);
		})
		.then((result) => {
			// If API returns a 200 request, but the entry doesn't have a jackpot value yet then we try again.
			if(result.drawNbrs.length === 0 && attemptedTries < maxAjaxAttempts) {
				return getWinningNumbers(game, drawDate, jurisdictionOverride, ++attemptedTries);
			}
			return result;
		})
		.catch((error) => {
			if(attemptedTries < maxAjaxAttempts) {
				return getWinningNumbers(game, drawDate, jurisdictionOverride, ++attemptedTries);
			}

			const apiError = new Error('🤦 API fetch failed ' + attemptedTries + ' times. Stacktrace: ' + error.stack + '\n ------ The above trace causes the following error ------');

			return Promise.reject(apiError);
		});
}

function getJackpot(game, attemptedTries = 0) {
	if(game.hasStaticJackpot() && !game.isDailyGrand()) {
		// mimic a response from the API and build out the fields we need for a static jackpot
		const jackpot = {
			jackpot: game.getStaticJackpotAmount(),
		};

		if(game.isWesternMax()) {
			jackpot.bonusDraws = 14;
		}

		return Promise.resolve(jackpot);
	}

	//let fetchURL = '/services2/lotto/jackpot/' + game.id;
	let fetchURL = '/services2/lotto/jackpotExtended/' + game.id;
	if (game.isPacificHEPoker()) {
		fetchURL = '/services2/'+ game.id + '/jackpot';
	}
	// for local testing
	//fetchURL = 'https://corporate.bclc.com' + fetchURL;
	//fetchURL = 'http://localhost' + fetchURL;

	//return fetch('/services2/lotto/jackpot/' + game.id)
	//return fetch(fetchURL + '?_=' + getCurrentTimestamp())
	return fetch(fetchURL)
		.then((response) => {
			return ajaxResponseErrorHandling(response);
		})
		.then((result) => {
			// If API returns a 200 request, but the entry doesn't have a jackpot value yet then we try again.
			if(result.jackpot === 0 && attemptedTries < 3) {
				return getJackpot(game, ++attemptedTries);
			}

			return result;
		})
		.catch((error) => {
			if(attemptedTries < maxAjaxAttempts) {
				return getJackpot(game, ++attemptedTries);
			}

			throw error;
		});
}

function getDrawLatest(attemptedTries = 0) {
	let fetchURL = '/services2/lotto/draw/latest';
	// for local testing
	//fetchURL = 'https://corporate.bclc.com' + fetchURL;
	//fetchURL = 'http://localhost' + fetchURL;

	//return fetch(fetchURL + '?_=' + getCurrentTimestamp())
	return fetch(fetchURL)
		.then((response) => {
			return ajaxResponseErrorHandling(response);
		})
		.then((result) => {
			// If API returns a 200 request, but the entry doesn't have a draw number value yet then we try again.
			if(result.drawNbr === 0 && attemptedTries < 3) {
				return getDrawLatest(++attemptedTries);
			}

			return result;
		})
		.catch((error) => {
			if(attemptedTries < maxAjaxAttempts) {
				return getDrawLatest(++attemptedTries);
			}

			throw error;
		});
}

function getCurrentTimestamp() {
	return new Date().getTime();
}

/**
 * Certain Manitoba games need data from the BC API. This function takes the BC data
 * and applies the MB data differences (currently only extra numbers) then returns the combined data.
 *
 * @param bcData
 * @param mbData
 * @returns {any}
 */
function combineBcAndMbData(bcData, mbData) {
	let combined = Object.assign({}, bcData);
	combined.extraNbrs = mbData.extraNbrs;

	return combined;
}

/**
 * Gets the URL for the
 *
 * @param game Game object from ./GameProvider
 * @param drawDate Date
 * @param jurisdictionOverride used to override the jurisdiction in case we need to make a BC call when on MB
 * @returns {string}
 */
function getWinningNumbersUrl(game, drawDate, jurisdictionOverride) {
	let url = '/services2/lotto/draw/';
	//url = 'https://corporate.bclc.com' + url;
	//url = 'http://localhost' + url;
	let gameId = game.id;

	if(isManitoba() && jurisdictionOverride !== playnow.switching.bc) {
		url += 'western/';

		// if lmax/six49 is passed in when it's MB, it's assumed that we're looking for the extra
		// numbers data to populate the lmax/six49 lotto
		if(game.isLottoMax()) {
			gameId = GameProvider.getWesternMaxGame().id;
		} else if(game.isLotto649()) {
			gameId = GameProvider.getWestern649Game().id;
		}
	}

	url += gameId + '/' + getFormattedIso8601Date(drawDate);

	return url;
}

function isManitoba() {
	return playnow.switching.jurisdiction === playnow.switching.mb;
}

function getFormattedIso8601Date(date) {
	if(!date) {
		return null;
	}

	let day = date.getDate();
	day = day < 10 ? '0' + day : day;

	let month = date.getMonth() + 1;
	month = month < 10 ? '0' + month : month;

	return date.getFullYear() + '-' + month + '-' + day;
}

/**
 * Certain Manitoba games need data from the BC API. The only difference between
 * the two data sets is the extra numbers (so the draws are the same except for the extra numbers)
 *
 * This function determines if the game is one of these special games that need further processing
 * after the API call
 *
 * @param game Game object from ./GameProvider
 * @returns {boolean}
 */
function gameNeedsBcData(game) {
	return (game.isLottoMax() || game.isLotto649() || game.isDailyGrand());
}

function parseServerDate(dateStr) {
	var a=dateStr.split(" ");
	var d=a[0].split("-");
	var t=a[1].split(":");
	var date = new Date(d[0],(d[1]-1),d[2],t[0],t[1],t[2]);
	return date;
}

function parseDrawDate(dateStr) {
	var a=dateStr.split("T");
	var d=a[0].split("-");
	var t=a[1].split(":");
	var date = new Date(d[0],(d[1]-1),d[2],t[0],t[1]);
	return date;
}

function getServerDateTime(attemptedTries = 0) {
	let fetchURL = '/services2/getdatetime/pt';
	//fetchURL = 'https://corporate.bclc.com' + fetchURL;
	//fetchURL = 'http://localhost' + fetchURL;

	//return fetch(fetchURL + '?_=' + getCurrentTimestamp())
	return fetch(fetchURL)
		.then((response) => {
			return ajaxTextResponseErrorHandling(response);
		})
		.then((result) => {
			let serverTime = parseServerDate(result);
			return serverTime;
		})
		.catch((error) => {
			if(attemptedTries < maxAjaxAttempts) {
				return getServerDateTime(++attemptedTries);
			}

			throw error;
		});
}

function getJackpotExtend(attemptedTries = 0) {

	// JackotExtend is depicated and merge with jackpot
	let fetchURL = '/services2/lotto/jackpot';
	// for local testing
	//fetchURL = 'https://corporate.bclc.com' + fetchURL;
	//fetchURL = 'http://localhost' + fetchURL;

	//return fetch(fetchURL + '?_=' + getCurrentTimestamp())
	return fetch(fetchURL)
		.then((response) => {
			return ajaxResponseErrorHandling(response);
		})
		.then((result) => {
			// If API returns a 200 request, but the entry doesn't have a jackpot value yet then we try again.
			if(result.jackpot === 0 && attemptedTries < 3) {
				return getJackpotExtend(++attemptedTries);
			}

			return result;
		})
		.catch((error) => {
			if(attemptedTries < maxAjaxAttempts) {
				return getJackpotExtend(++attemptedTries);
			}

			throw error;
		});
}

/**
 * If a game is to be drawn today, but it hasn't past the draw break time yet we dont want to display the card for the draw.
 * This method is used to hide the card until we hit draw break and then we can display the draw break card until the draw data exists.
 *
 * @returns {Boolean}
 */
function isDateInDrawBreakThreshold(today, date) {
	let isAfterDrawBreak = false;

	if (today && date) {
		let drawBreakDate = new Date(today.getTime());
		drawBreakDate.setHours(19, 30, 0, 0);
		isAfterDrawBreak = today.getTime() >= drawBreakDate.getTime() && today.getDay() === date.getDay();
	}
	return isAfterDrawBreak;
}

/**
 * If the game is today and is between 7:30PM and 11:59PM. Used to determine whether to display a draw break or a error message
 */
function isInDrawBreak(now, game) {
	let result = false;
	if (now && game) {
		let drawBreakDateStart = new Date(now.getTime());
		let drawBreakDateEnd = new Date(now.getTime());
		drawBreakDateStart.setHours(19, 30, 0, 0);
		drawBreakDateEnd.setHours(23, 59, 59, 0);

		result = game.drawsOnDate(now) &&
			now.getTime() >= drawBreakDateStart.getTime() &&
			now.getTime() <= drawBreakDateEnd.getTime();
	}
	return result;
}

function getDateFromClosestWeekDay(today, day) {
	let todayWeekDay = today.getDay();
	let dayDiff = day - todayWeekDay;

	if (dayDiff < 0) {
		// the week of day is in the past, find the next week of day
		dayDiff += 7;
	}
	let closestDate = today;
	closestDate.setDate(dayDiff);

	return closestDate;
}

export default {
	isManitoba() {
		return isManitoba();
	},

	/**
	 * Gets the winning numbers for a given game and date combination
	 *
	 * @param game Game object from ./GameProvider
	 * @param drawDate Date
	 */
	getWinningNumbers(game, drawDate) {
		if(isManitoba()) {
			const mbPromise = getWinningNumbers(game, drawDate, playnow.switching.mb);

			if(gameNeedsBcData(game)) {
				const bcPromise = getWinningNumbers(game, drawDate, playnow.switching.bc);

				return Promise.all([bcPromise, mbPromise]).then((results) => {
					return combineBcAndMbData(results[0], results[1]);
				});
			}

			return mbPromise;
		} else {
			return getWinningNumbers(game, drawDate);
		}
	},

	getJackpot(game) {
		return getJackpot(game);
	},

	/**
	 * MB needs a different url, so this is used for any navigation for MB
	 *
	 * @param url string
	 */
	navigate(url) {
		if(this.isManitoba() && url.indexOf('/mb') !== 0) {
			url = '/mb' + url;
		}

		window.location.href = url;
	},

	getServerDateTime() {
		return getServerDateTime();
	},

	getJackpotExtend() {
		return getJackpotExtend();
	},

	getDrawLatest() {
		return getDrawLatest();
	},

	isDateInDrawBreakThreshold(today, date) {
		return isDateInDrawBreakThreshold(today, date);
	},

	isInDrawBreak(now, game) {
		return isInDrawBreak(now, game);
	},

	getDateFromClosestWeekDay(today, day) {
		return getDateFromClosestWeekDay(today, day);
	},

	parseServerDate(dateStr) {
		return parseServerDate(dateStr);
	},

	parseDrawDate(dateStr) {
		return parseDrawDate(dateStr);
	}
};