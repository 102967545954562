import LotteryResources from './LotteryResources.js';

const LOTTO_MAX_ID = 'lmax';
const LOTTO_649_ID = 'six49';
const DAILY_GRAND_ID = 'dgrd';
const BC_49_ID = 'bc49';
const WESTERN_MAX_ID = 'max';
const WESTERN_649_ID = '649';
const PHEP_ID = 'phep';

/**
 * Array.includes() polyfill because IE is in the stone age
 */
if(!Array.prototype.includes) {
	Array.prototype.includes = function(search, start) {
		'use strict';
		if(typeof start !== 'number') {
			start = 0;
		}

		if(start + search.length > this.length) {
			return false;
		} else {
			return this.indexOf(search, start) !== -1;
		}
	};
}

/**
 * Represents one of the six games provided on PlayNow
 *
 * BC - BC49
 * MB - Western 649
 * MB - Western Max
 * Shared - Lotto Max
 * Shared - Lotto 649
 * Shared - Daily Grand
 */
let Game = {
	westernStaticJackpotAmount: 2e6, // 2 Million
	id: '',
	name: '',
	bonusDraws: '',
	drawDays: [],
	winningNumbersURL: '',
	buyTicketURL: '',
	buyTicketLabel: '',

	set: function(id, name, bonusDraws, drawDays) {
		this.id = id;
		this.name = name;
		this.bonusDraws = bonusDraws;
		this.drawDays = drawDays;
	},

	/**
	 * Determines if two Game objects refer to the same game
	 *
	 * @param game {Game|string}
	 * @returns {boolean}
	 */
	is: function(game) {
		if(game && game.id) {
			return this.id === game.id;
		}

		return this.id === game;
	},

	isOnlyWesternGame: function() {
		return this.isWesternMax() || this.isWestern649();
	},

	isOnlyBcGame: function() {
		return this.isBc49();
	},

	isSharedGame: function() {
		return this.isLottoMax() || this.isLotto649() || this.isDailyGrand();
	},

	isDailyGrand: function() {
		return this.id === DAILY_GRAND_ID;
	},

	isLotto649: function() {
		return this.id === LOTTO_649_ID;
	},

	isLottoMax: function() {
		return this.id === LOTTO_MAX_ID;
	},

	isBc49: function() {
		return this.id === BC_49_ID;
	},

	isWestern649: function() {
		return this.id === WESTERN_649_ID;
	},

	isWesternMax: function() {
		return this.id === WESTERN_MAX_ID;
	},

	isPacificHEPoker: function() {
		return this.id === PHEP_ID;
	},

	/**
	 * Find the next draw day in the week from a given date
	 * @param date
	 * @return {integer} (day of the week)
	 */
	nextDrawDay: function(date = null) {
		if(!date) {
			return null;
		}

		let nextDrawDay = 7,
		firstDrawDayOfTheWeek = 6,
		day = date.getDay();

		this.drawDays.forEach((drawDay) => {
			if (drawDay < firstDrawDayOfTheWeek) {
				firstDrawDayOfTheWeek = drawDay;
			}
			if (drawDay >= day && drawDay < nextDrawDay) {
				nextDrawDay = drawDay;
			}
		});

		if (nextDrawDay === 7) {
			// no draw day this week, the closest draw day is in next week
			nextDrawDay = firstDrawDayOfTheWeek;
		}

		return nextDrawDay;
	},

	/**
	 * Determines whether the game draws on a given date
	 * @param date
	 * @returns {boolean}
	 */
	drawsOnDate: function(date = null) {
		if(!date) {
			return false;
		}

		const lmax2StartDate = new Date(2019, 4, 11);

		if(this.isLottoMax() && date.getDay() === 2 && date <= lmax2StartDate) {
			return false;
		}

		return this.drawDays.includes(date.getDay());
	},

	/**
	 * Some Western games (Western Max & Western 649) have static jackpots that don't change.
	 *
	 * @returns {boolean}
	 */
	hasStaticJackpot: function() {
		return this.isDailyGrand() || this.isWestern649() || this.isWesternMax();
	},

	/**
	 * Some Western games (Western Max & Western 649) have static jackpots that don't change.
	 * For these games we do not request the API for the jackpot amount, and just return the value (2 million)
	 *
	 * @returns {null|number}
	 */
	getStaticJackpotAmount: function() {
		if(this.isWesternMax() || this.isWestern649()) {
			return this.westernStaticJackpotAmount;
		}

		return null;
	},

	/**
	 * Some games are watchable through a Youtube video
	 * This function determines whether or not this game is watchable
	 */
	isWatchable: function() {
		return this.isDailyGrand() || this.isLotto649() || this.isLottoMax();
	},

	getWinningNumbersUrl: function(date) {
		let url = '/lottery/';

		if(LotteryResources.isManitoba()) {
			url = '/mb' + url;
		}

		if(this.isLottoMax()) {
			url += 'lotto-max-winning-numbers';
		} else if(this.isLotto649()) {
			url += 'lotto-649-winning-numbers';
		} else if(this.isBc49()) {
			url += 'bc-49-winning-numbers';
		} else if(this.isDailyGrand()) {
			url += 'daily-grand-winning-numbers';
		} else if(this.isWesternMax()) {
			url += 'western-max-winning-numbers';
		} else if(this.isWestern649()) {
			url += 'western-649-winning-numbers';
		}

		if(date instanceof Date) {
			const dateParameter = this.formatDateForWinningNumbersUrl(date);
			url += '?date=' + dateParameter;
		}

		return url;
	},

	formatDateForWinningNumbersUrl: function(date) {
		return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
	},

	getProductWinningNumbersUrl: function() {
		let url = 'https://www.playnow.com/lottery/';

		if(this.isLottoMax()) {
			url += 'lotto-max-winning-numbers';
		} else if(this.isLotto649()) {
			url += 'lotto-649-winning-numbers';
		} else if(this.isBc49()) {
			url += 'bc-49-winning-numbers';
		} else if(this.isDailyGrand()) {
			url += 'daily-grand-winning-numbers';
		} else if(this.isWesternMax()) {
			url += 'western-max-winning-numbers';
		} else if(this.isWestern649()) {
			url += 'western-649-winning-numbers';
		}

		return url;
	},

	getProductBuyTicketsUrl: function() {
		let url = 'https://www.playnow.com/lottery/buy-tickets/';

		if(this.isPacificHEPoker()) {
			url = 'https://lotto.bclc.com/pacific-holdem-poker.html';
		}

		return url;
	},

	/**
	 * URL for the information page about each game (Game Info, How to Play, Prizes & Odds, etc.)
	 */
	getProductInformationUrl: function() {
		let url = 'https://www.playnow.com/lottery/';

		if(this.isLottoMax()) {
			url += 'lotto-max/';
		} else if(this.isLotto649()) {
			url += 'lotto-649/';
		} else if(this.isBc49()) {
			url += 'bc-49/';
		} else if(this.isDailyGrand()) {
			url += 'daily-grand/';
		} else if(this.isWesternMax()) {
			url += 'western-max/';
		} else if(this.isWestern649()) {
			url += 'western-649/';
		} else if(this.isPacificHEPoker()) {
			url = 'https://lotto.bclc.com/pacific-holdem-poker.html';
		}

		return url;
	},

	getPrizesAndOddsUrl: function() {
		return this.getProductInformationUrl() + '?tab=3';
	},

	getFrequentlyDrawnNumbersUrl: function() {
		let url = this.getProductInformationUrl();

		if(!this.isOnlyWesternGame()) {
			url += 'number-frequency/';
		}

		return url;
	},

	getLogoUrl: function() {
		const baseUrl = '/etc.clientlibs/bclc/clientlibs/clientlib-base/resources/svgs/';

		if(this.isDailyGrand()) {
			return baseUrl + 'logo-daily-grand.svg';
		} else if(this.isLotto649()) {
			return baseUrl + 'logo-lotto-649.svg';
		} else if(this.isLottoMax()) {
			return baseUrl + 'Lottomax_rgb_white_Pos.svg';
		} else if(this.isBc49()) {
			return baseUrl + 'logo-bc-49.svg';
		} else if(this.isWesternMax()) {
			return baseUrl + 'logo-western-max.png';
		} else if(this.isWestern649()) {
			return baseUrl + 'logo-western-649.png';
		} else if(this.isPacificHEPoker()) {
			return baseUrl + 'PHEP_4C.svg';
		}
	},

	getExtraLogoUrl: function() {
		const baseUrl = '/resources/images/lottery/winning-numbers/';

		if(this.isDailyGrand()) {
			return baseUrl + 'logo-daily-grand-extra.svg';
		} else if(this.isLotto649()) {
			return baseUrl + 'logo-lotto-649-extra.svg';
		} else if(this.isLottoMax()) {
			return baseUrl + 'logo-lotto-max-extra.svg';
		} else if(this.isBc49()) {
			return baseUrl + 'logo-lotto-649-extra.svg';
		} else if(this.isWesternMax() || this.isWestern649()) {
			return baseUrl + 'logo-extra-mb.png';
		}

		return 'logo-lotto-649.svg';
	},

	getShortDescription: function() {
		if(this.isLottoMax()) {
			return 'Bigger jackpots with Lotto Max, the nationwide lottery game with two weekly jackpots that start at $10 million and grow up to $70 million.';
		} else if(this.isLotto649()) {
			return 'Lotto 6/49 is the nationwide lottery game with more chances every week to become a millionaire.';
		} else if(this.isBc49()) {
			return 'With a top prize of $2 million, BC/49 is just another reason to love living in B.C.!';
		} else if(this.isDailyGrand()) {
			return 'The new national lottery game with a top prize of $1,000 a DAY for LIFE!';
		}

		return '';
	},

	setWinningNumbersURL: function (URL) {
		this.winningNumbersURL = URL;
	},

	getWinningNumbersURL: function () {
		return this.winningNumbersURL;
	},

	setBuyTicketURL: function (URL) {
		this.buyTicketURL = URL;
	},

	getBuyTicketURL: function () {
		return this.buyTicketURL;
	},

	setBuyTicketLabel: function (label) {
		this.buyTicketLabel = label;
	},

	getBuyTicketLabel: function () {
		return this.buyTicketLabel;
	}
};

/**
 * Provides access to the Game object to use for Lottery pages
 */
let GameProvider = {
	getBcGames: function() {
		return [
			this.getLottoMaxGame(),
			this.getLotto649Game(),
			this.getDailyGrandGame(),
			this.getBc49Game()
		];
	},

	getMbGames: function() {
		return [
			this.getLottoMaxGame(),
			this.getLotto649Game(),
			this.getDailyGrandGame(),
			this.getWesternMaxGame(),
			this.getWestern649Game()
		];
	},

	getGameById: function(gameId) {
		if(gameId === LOTTO_MAX_ID) {
			return this.getLottoMaxGame();
		} else if(gameId === LOTTO_649_ID) {
			return this.getLotto649Game();
		} else if(gameId === DAILY_GRAND_ID) {
			return this.getDailyGrandGame();
		} else if(gameId === BC_49_ID) {
			return this.getBc49Game();
		} else if(gameId === WESTERN_MAX_ID) {
			return this.getWesternMaxGame();
		} else if(gameId === WESTERN_649_ID) {
			return this.getWestern649Game();
		} else if(gameId === PHEP_ID) {
			return this.getPacificHEPokerGame();
		}

		return null;
	},

	getLottoMaxGame: function() {
		let game = Object.assign({}, Game);
		game.set(LOTTO_MAX_ID, 'Lotto Max', 'Maxmillions', [2, 5]);

		return game;
	},

	getLotto649Game: function() {
		let game = Object.assign({}, Game);
		game.set(LOTTO_649_ID, 'Lotto 6/49', 'Bonus Draws', [3, 6]);

		return game;
	},

	getDailyGrandGame: function() {
		let game = Object.assign({}, Game);
		game.set(DAILY_GRAND_ID, 'Daily Grand', 'Bonus Draws', [1, 4]);

		return game;
	},

	getBc49Game: function() {
		let game = Object.assign({}, Game);
		game.set(BC_49_ID, 'BC/49', 'Bonus Draws', [3, 6]);

		return game;
	},

	getWesternMaxGame: function() {
		let game = Object.assign({}, Game);
		game.set(WESTERN_MAX_ID, 'Western Max', '$1 Million Prizes', [2, 5]);

		return game;
	},

	getWestern649Game: function() {
		let game = Object.assign({}, Game);
		game.set(WESTERN_649_ID, 'Western 6/49', '$1 Million Prizes', [3, 6]);

		return game;
	},

	getPacificHEPokerGame: function() {
		let game = Object.assign({}, Game);
		game.set(PHEP_ID, 'Pacific Hold\'Em Poker', '', []);

		return game;
	}
};

export default GameProvider;