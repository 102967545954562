<template>
	<div
		class="jackpot__content"
		:class="'jackpot__content_' + game.id"
	>
		<div
			v-if="isError"
			class="jackpot__error">
			<i class="jackpot__error-icon fas fa-exclamation-circle"></i>
			<p class="jackpot__error-text">
				An error has occurred.<br/>Refresh or try again later.
			</p>
		</div>

		<div
			v-else-if="!isLoading && isDateInDrawBreakThreshold"
			class="jackpot__recentlydrawn"
		>
			<i class="jackpot__recentlydrawn-icon fas fa-exclamation-circle"></i>
			<p class="jackpot__recentlydrawn-text">
				Tonight's draw just ended.
			</p>
			<p v-if="!isTodayWinningNumbersAvailable"
				class="jackpot__recentlydrawn-subtext">
				We're gathering results.
			</p>
		</div>

		<div
			v-else-if="!isLoading && ((!game.isLotto649() && draw.jackpot > 0) || (game.isLotto649() && draw.six49GPJackpot > 0))"
			class="jackpot__amount_wrap"
		>
			<p v-if="game.isPacificHEPoker()" class="jackpot__label">
				Bad Beat Progressive Jackpot
			</p>

			<div
				v-if="game.isLotto649() && draw.six49AdditionalGPs > 0"
				class="block-ribbon"><span class="block-ribbon-sign">+</span><span class="block-ribbon-text">SUPER DRAW</span></div>
			<div
				v-if="game.isLotto649()"
				class="block-gold-ball"><img class="gold-ball-logo img-fluid" src="/etc.clientlibs/bclc/clientlibs/clientlib-base/resources/assets/GOLDBALL.svg" alt="gold-ball-logo" aria-hidden="true" /></div>

			<div class="jackpot__amount">
				<p class="jackpot__amount-text">
					<span class="jackpot__amount-dollar">$</span>
					<span class="jackpot__amount-number"
						v-html="getJackpotAmount()"
					>
						{{ getJackpotAmount() }}
					</span> <span
						v-if="!game.hasStaticJackpot() && !game.isPacificHEPoker() && !game.isLotto649()"
						class="jackpot__estimate"
					>
						EST.
					</span>
				</p>
			</div>
			<p v-if="game.isLotto649() && draw.six49GPWhiteBallsRemaining > 0" class="block-description"><span class="text">OR GUARANTEED ${{ formatPrize(draw.six49GPPrize) }} Million</span></p>
			<div
				v-if="game.isLotto649()"
				class="block-classic"><i class="fas fa-plus-circle"></i><span class="block-classic-description"><img class="classic-logo img-fluid" src="/etc.clientlibs/bclc/clientlibs/clientlib-base/resources/assets/CLASSIC.svg" alt="classic-logo" aria-hidden="true" />${{ formatPrize(draw.jackpot) }} MILLION</span></div>
<!--
			<div
				v-if="draw.six49GPs > 0 || draw.six49AdditionalGPs > 0"
				class="jackpot__guaranteed-prize_wrap"
			>
				<p
					v-if="draw.six49GPs"
					class="jackpot__guaranteed-prize"
				>
					<label
						class="jackpot__pill"
						:class="'jackpot__pill_active_' + game.id"
					> PLUS </label> <span class="jackpot__guaranteed-prize-text">
						{{ draw.six49GPs }} GUARANTEED<br/>$1 Million Prize
					</span>
				</p>
				<p
					v-if="draw.six49AdditionalGPs > 0"
					class="jackpot__guaranteed-additional-prize"
				>
					<label
						class="jackpot__pill"
						:class="'jackpot__pill_active_' + game.id"
					> AND </label> <span class="jackpot__guaranteed-additional-prize-text">
						{{ draw.six49AdditionalGPs }} GUARANTEED<br/>Additional Prizes
					</span>
				</p>
			</div>
-->
			<div
				v-if="draw.bonusDraws > 0"
				class="jackpot__bonus"
			>
				<p class="jackpot__prize-breakdown__navigation">
					<img
						v-if="game.isLottoMax()"
						alt="maxmillion-logo"
						class="jackpot__maxmillion-logo"
						:src="logos.maxMillionLogo"
					>
					<label
						v-else
						class="jackpot__pill"
						:class="'jackpot__pill_active_' + game.id"
					> PLUS </label>
					<span v-if="game.isDailyGrand()" class="jackpot__bonus-draw-text">
						{{ draw.bonusDraws }} {{ game.bonusDraws }}
					</span>
					<span v-else class="jackpot__bonus-draw-text">
						{{ draw.bonusDraws }} {{ game.bonusDraws.toUpperCase() }}
					</span>
				</p>
			</div>
		</div>

		<div
			v-else-if="isLoading"
			class="jackpot__loading"
		>
			<p class="jackpot__loading-text loading">
				<!--Loading jackpot-->
				<span>.</span><span>.</span><span>.</span><span>.</span>
			</p>
		</div>

		<div
			v-else
			class="jackpot__tba"
		>
			<div class="jackpot__amount">
				<p class="jackpot__amount-text">
					<span class="jackpot__amount-dollar"></span> <span class="jackpot__amount-number">
						TBA
					</span>
				</p>
			</div>
		</div>

		<div class="jackpot__divider"></div>

		<jackpot-card-footer
			v-if="((!game.isLotto649() && draw.jackpot > 0) || (game.isLotto649() && draw.six49GPJackpot > 0))
				|| isError || isTodayWinningNumbersAvailable"
			:game="game"
			:draw="draw"
		/>
	</div>
</template>
<script>
	import JackpotCardFooter from './JackpotCardFooter.vue';
	import LotteryResources from '../../library/lotto-platform/LotteryResources.js';

	export default {
		name: 'JackpotCardContent',
		components: {
			JackpotCardFooter,
		},
		props: {
			game: {
				type: Object,
				required: true
			},
			drawDate: {
				//type: Date,
				required: true
			},
			draw: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				logos: {
					maxMillionLogo: '/etc.clientlibs/bclc/clientlibs/clientlib-base/resources/assets/logo-lotto-max-maxmillion.png',
					maxMillionPendingIcon: '/etc.clientlibs/bclc/clientlibs/clientlib-base/resources/assets/logo-lotto-max-maxmillion-pending.png',
				},
				odooInstance: null
			};
		},
		computed: {
			isLoading() {
				return Object.keys(this.draw).length === 0 && this.draw.constructor === Object
					&& this.day === undefined && this.drawDate === ""
			},
			isError() {
				return this.$store.state.JackpotStore.errored;
			},
			isDateInDrawBreakThreshold() {
				return LotteryResources.isInDrawBreak(this.$store.state.JackpotStore.serverDateTime, this.game);
			},
			isTodayWinningNumbersAvailable() {
				return this.$store.getters.isTodayWinningNumbersAvailable(this.game.id);
			},
		},
		updated: function() {
				let amount, className;
				className = 'jackpot_'+ this.game.id;
				if ($('.jackpot_'+ this.game.id + ' .jackpot__amount-number .amount').length > 0) {
					if(this.game.isDailyGrand()) {
						let annuityDetails = this.draw.annuityDetails;
						amount = annuityDetails.annuityAmount;
						/*
						if(typeof amount === 'number') {
							amount = amount.toLocaleString('en-US');
						}*/
					}  else if (this.game.isPacificHEPoker()) {
						amount = this.draw.jackpot;
						if(typeof amount === 'number') {
							amount = Math.floor(amount).toLocaleString('en-US');
						}
					} else if (this.game.isLotto649()) {
						amount = this.draw.six49GPJackpot / 1000000;
					} else {
						amount = this.draw.jackpot / 1000000;
					}

					this.odooInstance = this.$odoo({
						el: '.jackpot_'+ this.game.id + ' .jackpot__amount-number .amount', 
						value: amount,
						animationDelay: 0,
						duration: 1500
					});
					console.log("game: " + this.game.id + ", amount: " + amount);
				}
		},
		methods: {
			/**
			 * Returns the jackpot amount formatted. If the game is daily grand it formats the annuity amount.
			 *
			 * @returns {string}
			 */
			getJackpotAmount() {
				let amountString = '';

				if(this.game.isDailyGrand()) {
					let annuityDetails = this.draw.annuityDetails;
					//let annuityAmount = annuityDetails.annuityAmount;
					let annuityFrequency = annuityDetails.annuityFrequency;
					let annuityDuration = annuityDetails.annuityDuration;
/*
					if(typeof annuityAmount === 'number') {
						annuityAmount = annuityAmount.toLocaleString('en-US');
					}
*/
					if(typeof annuityFrequency === 'string') {
						annuityFrequency = annuityFrequency.toUpperCase();
					}

					if(typeof annuityDuration === 'string') {
						annuityDuration = annuityDuration.toUpperCase();
					}

					//amountString += '<span class="amount">' + annuityAmount + '</span> a ' + annuityFrequency + '<br> for ' + annuityDuration;
					amountString += '<span class="amount"></span><span class="frequency-text"> a ' + annuityFrequency + '<br /> for ' + annuityDuration + '</span>';
				} else if (this.game.isPacificHEPoker()) {
					amountString += '<span class="amount"></span>';
				} else {
					//let numberOfMillions = this.draw.jackpot / 1000000;
					//amountString += '<span class="amount">' + numberOfMillions + '</span> Million';
					amountString += '<span class="amount"></span> <span class="units">Million</span>';
				}

				return amountString;
			},

			getDayNameFromDate(date) {
				const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

				return daysOfWeek[date.getDay()];
			},

			formatPrize(prize) {
				let formattedPrize = prize / 1000000
				return formattedPrize;
			}
		}
	};
</script>