var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jackpot__body", class: "jackpot__body_" + _vm.game.id },
    [
      _c("jackpot-card-image", { attrs: { game: _vm.game } }),
      _vm._v(" "),
      _c("jackpot-card-content", {
        attrs: {
          game: _vm.game,
          day: _vm.day,
          "draw-date": _vm.drawDate,
          draw: _vm.draw
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }