<template>
	<div v-if="isTimeLoaded" class="container__loading">
			<p class="jackpot__loading-text loading">
				<span>.</span><span>.</span><span>.</span><span>.</span>
			</p>
	</div>
	<div v-else>
		<swiper class="swiper" ref="swiper" :options="swiperOptions" @ready="handleSwiperLoaded">
			<slot name="marketing-tiles"></slot>
			<template v-for="weekDate in getDaysInCurrentWeek()">
				<template v-for="game in getGamesByDate(weekDate)">
					<template v-if="isNearestGameToDate(game, weekDate)">
						<swiper-slide :key="'lottery-' + game.id" :id="'lottery-' + game.id" class="tile">
							<jackpot-card
								:key="game.id + `-${weekDate.getDay()}`"
								:day="weekDate.getDay()"
								:draw-date="weekDate"
								:game="game"
								:cardClickable="false"
							/>
						</swiper-slide>
					</template>
				</template>
				<template v-if="isSunday(weekDate)">
					<slot name="subscription-tiles"></slot>
				</template>
			</template>
			<slot name="product-cards"></slot>
			<!-- If we need navigation buttons -->
			<div class="swiper-button-prev" slot="button-prev" @click="prev()"></div>
			<div class="swiper-button-next" slot="button-next" @click="next()"></div>

			<!-- If we need scrollbar -->
			<div class="swiper-scrollbar" slot="scrollbar"></div>
		</swiper>
	</div>
</template>
<script>
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	import JackpotCard from './LotteryJackpotCard/JackpotCard.vue';
	import GameProvider from '../library/lotto-platform/GameProvider.js';

	export default {
		name: 'LotteryJackpotContainer',
		components: {
			Swiper,
			SwiperSlide,
			JackpotCard
		},
		directives: {
			swiper: directive
		},
		data() {
			return {
				games: this.gamesData(),
				swiperOptions: {
					slidesPerView: 'auto',
					freeMode: true,
					watchSlidesProgress: true,
					watchSlidesVisibility: true,
					grabCursor: true,
					spaceBetween: 24,
					breakpoints: {
						// when window width is >= 1200px
						1200: {
						spaceBetween: 32
						}
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					scrollbar: {
						el: '.swiper-scrollbar',
						draggable: true,
					},
				},
			};
		},
		computed: {
			isTimeLoaded() {
				let result = this.$store.state.JackpotStore.serverDateTime === ""
							&& !this.$store.state.JackpotStore.errored;
				if (result !== true) {
					this.$nextTick(() => {
						this.sentMountedEvent();
					});
				}
				return result;
			},
			now() {
				return this.dateData();
			},
			swiper() {
				return this.$refs.swiper.$swiper
			}
		},
		watch: {
			isTimeLoaded(newValue, oldValue) {
				console.log(`isTimeLoaded updating from ${oldValue} to ${newValue}`);
			}
		},
		created() {
			this.$store.dispatch('getServerDateTime');
		},
		mounted: function(){
			//this.$refs.swiper.$swiper.update();
		},
		methods: {
			sentMountedEvent() {
				//trigger an update on the page body.
				let event = new CustomEvent("VueJSMounted", {
					detail: {
						message: "Mounted VueJs",
						time: new Date(),
					},
					bubbles: true,
					cancelable: true
				});
				document.body.dispatchEvent(event);
			},
			prev() {
				this.$refs.swiper.$swiper.slidePrev(100);
			},
			next() {
				this.$refs.swiper.$swiper.slideNext(100);
			},
			dateData() {
				let date = this.$store.state.JackpotStore.serverDateTime;
				if (date === "") {
					date = new Date();
				}
				return date;
			},

			gamesData() {
				/* 	return GameProvider.getBcGames();
					return LotteryResources.isManitoba() ?
					GameProvider.getMbGames() :
					GameProvider.getBcGames()*/
				let games = GameProvider.getBcGames();
				games.forEach((game) => {
					if(game.isLottoMax()) {
						game.setBuyTicketLabel(this.$attrs.lmctalabel);
						game.setBuyTicketURL(this.$attrs.lmctaurl);
						game.setWinningNumbersURL(this.$attrs.lmwinningnumbersurl);
					} else if(game.isLotto649()) {
						game.setBuyTicketLabel(this.$attrs.sixctalabel);
						game.setBuyTicketURL(this.$attrs.sixctaurl);
						game.setWinningNumbersURL(this.$attrs.sixwinningnumbersurl);
					} else if(game.isBc49()) {
						game.setBuyTicketLabel(this.$attrs.bcctalabel);
						game.setBuyTicketURL(this.$attrs.bcctaurl);
						game.setWinningNumbersURL(this.$attrs.bcwinningnumbersurl);
					}else if(game.isDailyGrand()) {
						game.setBuyTicketLabel(this.$attrs.dgctalabel);
						game.setBuyTicketURL(this.$attrs.dgctaurl);
						game.setWinningNumbersURL(this.$attrs.dgwinningnumbersurl);
					}
				});
				return games;
			},

			/**
			 * Check if the game date is the closest to today
			 *
			 * @returns {Boolean}
			 */

			isNearestGameToDate(game, date) {
				console.log(`game: ${game.id}, date: ${date.getDay()}, gameDrawDays: ${game.drawDays} `);
				return game.nextDrawDay(this.now) == date.getDay();
			},

			/**
			 * If a game is to be drawn today, but it hasn't past the draw break time yet we dont want to display the card for the draw.
			 * This method is used to hide the card until we hit draw break and then we can display the draw break card until the draw data exists.
			 *
			 * @returns {Boolean}
			 */
			isDateInDrawBreakThreshold(date) {
				let today = this.now;
				let drawBreakDate = this.now;
				drawBreakDate.setHours(19, 30, 0, 0);

				return today.getTime() >= drawBreakDate.getTime() && today.getDay() === date.getDay();
			},

			/**
			 * Check if a specific date is past today except if the date parameter is past today then we want to check if the date is past the
			 * parameters value. This is for the case when we need to time travel forward for testing.
			 *
			 * @returns {Boolean}
			 */
			isPastToday(date) {
				let today =this.now;
				today.setHours(0, 0, 0, 0);

				return date.getTime() >= today.getTime();
			},

			/**
			 * Check if a specific date is Sunday
			 *
			 * @returns {Boolean}
			 */
			isSunday(date) {
				return date != null && date.getDay() == 0;
			},

						/**
			 * Returns an array of the day in the current selected week
			 *
			 * @returns {Array}
			 */
			getDaysInCurrentWeek() {
				//return this.getArrayOfDaysInWeek(this.startDate);
				return this.getArrayOfDaysFromDate(this.now);
			},

			/**
			 * Returns 6 days from a specific date.
			 *
			 * @returns {Array}
			 */
			getArrayOfDaysFromDate(date) {
				let days = [];
				let firstDay = new Date(date);
				let currentDate = firstDay;
				let last = firstDay.getDate() + 6;
				let lastDayOfWeek = new Date(firstDay);
				lastDayOfWeek.setDate(last);
				currentDate.setHours(0, 0, 0, 0);

				while(currentDate <= lastDayOfWeek) {
					days.push(new Date(currentDate));
					currentDate.setDate(currentDate.getDate() + 1);
				}

				return days;
			},

			getGamesByDate(date) {
				let games = [];

				console.log(`get games from date: ${date}`);
				this.games.forEach((game) => {
					// Let's push the game in the array only once
					if(game.drawsOnDate(date)) {
						console.log(`push game.id: ${game.id}`);
						games.push(game);
					}
				});

				return games;
			},

			handleSwiperLoaded(swiper) {
				//console.log('Swiper was mounted!', swiper);
				if (swiper.slides) {
					//console.log('Swiper slides', swiper.slides);
					swiper.slides.each(function(index) {
						if ($(this).hasClass("tile")) {
							$(this).attr("target", index+1);
						}
						else {
							$(this).children(".tile").attr("target", index+1);
						}
					});
				}
			}
		}
	};
</script>