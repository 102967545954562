<template>
	<div class="jackpot__header">
		<!--p class="jackpot__date">
			{{ headerDate }}
		</p-->
		<p v-if="game.isPacificHEPoker()"
			class="jackpot__day">
			Every 6:00 minutes
		</p>
		<p v-else-if="isServerDateAvailable && !isLoadingDraw" class="jackpot__day">
			<!--
			{{ draw.day }}'s {{ game.isDailyGrand() ? 'TOP PRIZE' : 'Jackpot' }}
			{{ now.getDay() == drawDate.getDay() ? 'Today' : draw.day }}'s Jackpot
			-->
			{{ header }}
		</p>
	</div>
</template>
<script>
import LotteryResources from '../../library/lotto-platform/LotteryResources.js';

	export default {
		name: 'JackpotCardHeader',
		props: {
			game: {
				type: Object,
				required: true
			},
			drawDate: {
				//type: Date,
				required: true
			},
			draw: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				now: new Date()
			}
		},
		computed: {
			/*
			headerDate() {
				const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
				const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

				return dayNames[this.drawDate.getDay()] + ', ' + monthNames[this.drawDate.getMonth()] + ' ' + this.drawDate.getDate();
			}*/
			isServerDateAvailable() {
				return !isNaN(this.$store.state.JackpotStore.serverDateTime) && this.$store.state.JackpotStore.serverDateTime !== "";
			},
			isLoadingDraw() {
				return Object.keys(this.draw).length === 0 && this.draw.constructor === Object
					&& this.drawDate === ""
			},
			header() {
				let header = "";
				let now = this.$store.state.JackpotStore.serverDateTime;
				if (now && this.drawDate) {
					now = new Date(now);
					if (LotteryResources.isInDrawBreak(now, this.game) || 
						now.getDay() == this.drawDate.getDay()) {
						header = "Tonight";
					}
					else {
						header = this.draw.day;
					}
					if (header) {
						header = header + "'s Jackpot";
					}
				}
				console.log("Vue.js JackpotCardHeader: header: " + header);
				return header;
			}
		},
		watch: {
			isServerDateAvailable(newValue, oldValue) {
				console.log(`isServerDateAvailable updating from ${oldValue} to ${newValue}`);
			}
		}
	};
</script>