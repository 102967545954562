<template>
	<div v-if="isError"
		class="jackpot__buy-ticket-container">
		<a @click="refresh"
			class="jackpot__button jackpot__refresh"
			ref="button"
		> <i class="fas fa-redo-alt"/> Refresh </a>
	</div>
	<div v-else-if="isDateInDrawBreakThreshold && isTodayWinningNumbersAvailable"
		class="jackpot__buy-ticket-container">
		<a class="jackpot__button jackpot__winning-number"
			ref="button"
			@click="navigateToWinningNumbers"
		>
			<i class="jackpot__tba-icon fas fa-calendar-alt"></i>
			<span>See winning numbers</span>
		</a>
	</div>
	<div v-else-if="!isDateInDrawBreakThreshold"
		class="jackpot__buy-ticket-container">
		<a v-if="this.label"
			class="jackpot__button jackpot__buy-tickets"
			ref="button"
			@click="navigateToBuyTickets">
			{{ this.label }}
		</a>
		<a v-else
			class="jackpot__button jackpot__buy-tickets"
			ref="button"
			@click="navigateToBuyTickets"
		> Play online </a>
	</div>
</template>
<script>
	import LotteryResources from '../../library/lotto-platform/LotteryResources.js';

	export default {
		name: 'JackpotCardFooter',
		props: {
			game: {
				type: Object,
				required: true
			},
			draw: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				label: this.game.getBuyTicketLabel()
			}
		},
		computed: {
			isError() {
				return this.$store.state.JackpotStore.errored;
			},
			isDateInDrawBreakThreshold() {
				return LotteryResources.isInDrawBreak(this.$store.state.JackpotStore.serverDateTime, this.game);
			},
			isTodayWinningNumbersAvailable() {
				return this.$store.getters.isTodayWinningNumbersAvailable(this.game.id);
			},
		},
		methods: {
			navigateToBuyTickets() {
				let url = this.game.getBuyTicketURL();
				if (!url) {
					url = this.game.getProductBuyTicketsUrl();
				}
				//window.location.href = url;
				window.open(url, "_self");
			},
			refresh() {
				this.$store.dispatch('reload');
			},
			navigateToWinningNumbers() {
				let url = this.game.getWinningNumbersURL();
				if (!url) {
					url = this.game.getProductWinningNumbersUrl();
				}
				//window.location.href = url;
				window.open(url, "_self");
			}
		}
	};
</script>