var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTimeLoaded
    ? _c("div", { staticClass: "container__loading" }, [_vm._m(0)])
    : _c(
        "div",
        [
          _c(
            "swiper",
            {
              ref: "swiper",
              staticClass: "swiper",
              attrs: { options: _vm.swiperOptions },
              on: { ready: _vm.handleSwiperLoaded }
            },
            [
              _vm._t("marketing-tiles"),
              _vm._v(" "),
              _vm._l(_vm.getDaysInCurrentWeek(), function(weekDate) {
                return [
                  _vm._l(_vm.getGamesByDate(weekDate), function(game) {
                    return [
                      _vm.isNearestGameToDate(game, weekDate)
                        ? [
                            _c(
                              "swiper-slide",
                              {
                                key: "lottery-" + game.id,
                                staticClass: "tile",
                                attrs: { id: "lottery-" + game.id }
                              },
                              [
                                _c("jackpot-card", {
                                  key: game.id + "-" + weekDate.getDay(),
                                  attrs: {
                                    day: weekDate.getDay(),
                                    "draw-date": weekDate,
                                    game: game,
                                    cardClickable: false
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ]
                  }),
                  _vm._v(" "),
                  _vm.isSunday(weekDate)
                    ? [_vm._t("subscription-tiles")]
                    : _vm._e()
                ]
              }),
              _vm._v(" "),
              _vm._t("product-cards"),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-button-prev",
                attrs: { slot: "button-prev" },
                on: {
                  click: function($event) {
                    return _vm.prev()
                  }
                },
                slot: "button-prev"
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-button-next",
                attrs: { slot: "button-next" },
                on: {
                  click: function($event) {
                    return _vm.next()
                  }
                },
                slot: "button-next"
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-scrollbar",
                attrs: { slot: "scrollbar" },
                slot: "scrollbar"
              })
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "jackpot__loading-text loading" }, [
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }