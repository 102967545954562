var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isError
    ? _c("div", { staticClass: "jackpot__buy-ticket-container" }, [
        _c(
          "a",
          {
            ref: "button",
            staticClass: "jackpot__button jackpot__refresh",
            on: { click: _vm.refresh }
          },
          [_c("i", { staticClass: "fas fa-redo-alt" }), _vm._v(" Refresh ")]
        )
      ])
    : _vm.isDateInDrawBreakThreshold && _vm.isTodayWinningNumbersAvailable
    ? _c("div", { staticClass: "jackpot__buy-ticket-container" }, [
        _c(
          "a",
          {
            ref: "button",
            staticClass: "jackpot__button jackpot__winning-number",
            on: { click: _vm.navigateToWinningNumbers }
          },
          [
            _c("i", { staticClass: "jackpot__tba-icon fas fa-calendar-alt" }),
            _vm._v(" "),
            _c("span", [_vm._v("See winning numbers")])
          ]
        )
      ])
    : !_vm.isDateInDrawBreakThreshold
    ? _c("div", { staticClass: "jackpot__buy-ticket-container" }, [
        this.label
          ? _c(
              "a",
              {
                ref: "button",
                staticClass: "jackpot__button jackpot__buy-tickets",
                on: { click: _vm.navigateToBuyTickets }
              },
              [_vm._v("\n\t\t" + _vm._s(this.label) + "\n\t")]
            )
          : _c(
              "a",
              {
                ref: "button",
                staticClass: "jackpot__button jackpot__buy-tickets",
                on: { click: _vm.navigateToBuyTickets }
              },
              [_vm._v(" Play online ")]
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }