import LotteryResources from '../../library/lotto-platform/LotteryResources.js';
import GameProvider from '../../library/lotto-platform/GameProvider.js';

const state = {
    serverDateTime: "",
    jackpotExtendInfo: {},
    phepJackpotInfo: {},
    drawLatestInfo: {},
    canLoadJackpotExtend: true,
    canLoadPHEPJackpot: true,
    canLoadDrawLatest: true,
    loading: new Map(),
    loadingTracker: 1,
    isLoading: true,
    errored: false
};

const mutations = {
    setServerDateTime(state, serverDateTime) {
        state.serverDateTime = serverDateTime;
    },
    setJackpotExtend(state, jackpots) {
        state.jackpotExtendInfo = jackpots;
    },
    setPHEPJackpot(state, jackpots) {
        state.phepJackpotInfo = jackpots;
    },
    setDrawLatest(state, draws) {
        state.drawLatestInfo = draws;
    },
    setError(state, error) {
        state.errored = error;
    },
    setLoading(state, payload) {
        let isLoading = false;
        state.loading.set(payload.gameId, payload.loading);
        state.loadingTracker += 1;
        for (let [key, value] in state.loading) {
            if (value == true) {
                isLoading = true;
            }
        }
        state.isLoading = isLoading;
    },
    setCanLoadJackpotExtend(state, canLoad) {
        state.canLoadJackpotExtend = canLoad;
    },
    setCanLoadPHEPJackpot(state, canLoad) {
        state.canLoadPHEPJackpot = canLoad;
    },
    setCanLoadDrawLatest(state, canLoad) {
        state.canLoadDrawLatest = canLoad;
    },
    updateJackpotExtendLoadingState(state, info) {
        let isLoading = false;
        for (var key in info) {
            state.loading.set(key, false);
        }
        for (let [key, value] in state.loading) {
            if (value == true) {
                isLoading = true;
            }
        }
        state.isLoading = isLoading;
        state.loadingTracker += 1;
    },
    resetState(state) {
        state.canLoadJackpotExtend = true,
        state.canLoadPHEPJackpot = true,
        state.canLoadDrawLatest = true,
        state.loading = new Map(),
        state.loadingTracker = 1,
        state.isLoading = true,
        state.errored = false
    }
};

const actions = {
    initializeJackpotStore({
        commit,
        dispatch,
        state
    }) {
        dispatch("getServerDateTime");
        dispatch("getPhepJackpot");
        dispatch("getJackpotExtend");
        dispatch("getDrawLatest");
    },
    reload({
        commit,
        dispatch,
        state
    }) {
        commit('resetState');
        dispatch('initializeJackpotStore');
    },
    getPhepJackpot({
        commit,
        state
    }) {
        if (state.canLoadPHEPJackpot) {
            // use this to make loading only happen once
            commit('setCanLoadPHEPJackpot', false);
            LotteryResources.getJackpot(GameProvider.getPacificHEPokerGame())
                .then((result) => {
                    console.log("Vue.js Jackpots: get PHEP Jackpot from Service2");
                    commit('setPHEPJackpot', result);
                    commit('setLoading', { gameId: 'PHEP', loading: false });
                }).catch((error) => {
                    console.log('ERROR on get PHEP jackpots from Service2 ');
                    commit('setError', true);
                });
        }
    },
    getJackpotExtend({
        commit,
        state
    }) {
        if (state.canLoadJackpotExtend) {
            // use this to make loading only happen once
            commit('setCanLoadJackpotExtend', false);
            LotteryResources.getJackpotExtend()
                .then((result) => {
                    console.log("Vue.js Jackpots: get jackpot Extend from Service2");
                    commit('setJackpotExtend', result);
                    commit('updateJackpotExtendLoadingState', result);
                }).catch((error) => {
                    console.log('ERROR on get jackpots Extend from Service2 ');
                    commit('setError', true);
                });
        }
    },
    getDrawLatest({
        commit,
        state
    }) {
        if (state.canLoadDrawLatest) {
            // use this to make loading only happen once
            commit('setCanLoadDrawLatest', false);
            LotteryResources.getDrawLatest()
                .then((result) => {
                    console.log("Vue.js draw latest: get latest winning numbers from Service2");
                    commit('setDrawLatest', result);
                }).catch((error) => {
                    console.log('ERROR on get draw latest from Service2 ');
                    commit('setError', true);
                });
        }
    },
    getServerDateTime({
        commit,
        state
    }) {
        LotteryResources.getServerDateTime()
        .then((result) => {
            console.log("Vue.js ServerDateTime: get ServerDateTime from Service2");
            commit('setServerDateTime', result);
        }).catch((error) => {
            console.log('ERROR on get ServerDateTime from Service2 ');
            commit('setError', true);
        });
    },
    /*computed: {
        jackpots() {
            return this.$store.state.jackpots;
        },
        jackpot(type) {
            return this.$store.state.jackpots.$type;
        }
    },*/
};

const getters = {
    isTodayWinningNumbersAvailable: (state) => (gameId) => {
        let result = false;
        let drawNbrs;
        let drawDate;

        if (state.drawLatestInfo[gameId.toUpperCase()]) {
            drawNbrs = state.drawLatestInfo[gameId.toUpperCase()].drawNbrs;
            drawDate = state.drawLatestInfo[gameId.toUpperCase()].drawDate;

            if (drawNbrs && drawDate) {
                let serverDate = new Date(state.serverDateTime);
                drawDate = new Date(Date.parse(drawDate));
                if (serverDate && drawDate) {
                    result = serverDate.getYear() == drawDate.getYear() && serverDate.getMonth() == drawDate.getMonth() && serverDate.getDate() == drawDate.getDate();
                }
            }
        }

        return result;
    },
    getDraw: (state) => (gameId) => {
        let draw;
        if (gameId === "phep") {
            draw = state.phepJackpotInfo;
        }
        else {
            draw = state.jackpotExtendInfo[gameId.toUpperCase()];
        }
        return draw;
    },
    getNextDrawDate: (state) => (gameId) => {
        let nextDrawDate;
        if (state.jackpotExtendInfo[gameId.toUpperCase()]) {
            nextDrawDate = state.jackpotExtendInfo[gameId.toUpperCase()].nextDrawDate;
            if (nextDrawDate) {
                //nextDrawDate = new Date(nextDrawDate);
                nextDrawDate = LotteryResources.parseDrawDate(nextDrawDate);
            }
        }
        return nextDrawDate;
    },
    getLoading: (state) => (gameId) => {
        let result = state.loading.get(gameId.toUpperCase());
        if (result === undefined) {
            result = true;
        }
        return result;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};