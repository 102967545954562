import Vue from 'vue';
import LotteryJackpotCard from './components/LotteryJackpotCard.vue';
import LotteryProductContainer from './components/LotteryProductContainer.vue';
import GenericProductContainer from './components/GenericProductContainer.vue';

const components = [];

components.push(...[
    { name: 'lottery-jackpot-card', component: LotteryJackpotCard },
    { name: 'lottery-product-container', component: LotteryProductContainer },
    { name: 'generic-product-container', component: GenericProductContainer }
]);

export default components;