var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jackpot__logo", class: "jackpot__logo_" + _vm.game.id },
    [
      _c("img", {
        staticClass: "jackpot__logo-image",
        class: "jackpot__logo-image__" + _vm.game.id,
        attrs: { alt: _vm.game.name, src: _vm.game.getLogoUrl() }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }