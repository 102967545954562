var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "jackpot__content",
      class: "jackpot__content_" + _vm.game.id
    },
    [
      _vm.isError
        ? _c("div", { staticClass: "jackpot__error" }, [
            _c("i", {
              staticClass: "jackpot__error-icon fas fa-exclamation-circle"
            }),
            _vm._v(" "),
            _vm._m(0)
          ])
        : !_vm.isLoading && _vm.isDateInDrawBreakThreshold
        ? _c("div", { staticClass: "jackpot__recentlydrawn" }, [
            _c("i", {
              staticClass:
                "jackpot__recentlydrawn-icon fas fa-exclamation-circle"
            }),
            _vm._v(" "),
            _c("p", { staticClass: "jackpot__recentlydrawn-text" }, [
              _vm._v("\n\t\t\t\tTonight's draw just ended.\n\t\t\t")
            ]),
            _vm._v(" "),
            !_vm.isTodayWinningNumbersAvailable
              ? _c("p", { staticClass: "jackpot__recentlydrawn-subtext" }, [
                  _vm._v("\n\t\t\t\tWe're gathering results.\n\t\t\t")
                ])
              : _vm._e()
          ])
        : !_vm.isLoading &&
          ((!_vm.game.isLotto649() && _vm.draw.jackpot > 0) ||
            (_vm.game.isLotto649() && _vm.draw.six49GPJackpot > 0))
        ? _c("div", { staticClass: "jackpot__amount_wrap" }, [
            _vm.game.isPacificHEPoker()
              ? _c("p", { staticClass: "jackpot__label" }, [
                  _vm._v("\n\t\t\t\tBad Beat Progressive Jackpot\n\t\t\t")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.game.isLotto649() && _vm.draw.six49AdditionalGPs > 0
              ? _c("div", { staticClass: "block-ribbon" }, [
                  _c("span", { staticClass: "block-ribbon-sign" }, [
                    _vm._v("+")
                  ]),
                  _c("span", { staticClass: "block-ribbon-text" }, [
                    _vm._v("SUPER DRAW")
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.game.isLotto649()
              ? _c("div", { staticClass: "block-gold-ball" }, [
                  _c("img", {
                    staticClass: "gold-ball-logo img-fluid",
                    attrs: {
                      src:
                        "/etc.clientlibs/bclc/clientlibs/clientlib-base/resources/assets/GOLDBALL.svg",
                      alt: "gold-ball-logo",
                      "aria-hidden": "true"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "jackpot__amount" }, [
              _c("p", { staticClass: "jackpot__amount-text" }, [
                _c("span", { staticClass: "jackpot__amount-dollar" }, [
                  _vm._v("$")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "jackpot__amount-number",
                    domProps: { innerHTML: _vm._s(_vm.getJackpotAmount()) }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.getJackpotAmount()) +
                        "\n\t\t\t\t\t"
                    )
                  ]
                ),
                _vm._v(" "),
                !_vm.game.hasStaticJackpot() &&
                !_vm.game.isPacificHEPoker() &&
                !_vm.game.isLotto649()
                  ? _c("span", { staticClass: "jackpot__estimate" }, [
                      _vm._v("\n\t\t\t\t\t\tEST.\n\t\t\t\t\t")
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.game.isLotto649() && _vm.draw.six49GPWhiteBallsRemaining > 0
              ? _c("p", { staticClass: "block-description" }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "OR GUARANTEED $" +
                        _vm._s(_vm.formatPrize(_vm.draw.six49GPPrize)) +
                        " Million"
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.game.isLotto649()
              ? _c("div", { staticClass: "block-classic" }, [
                  _c("i", { staticClass: "fas fa-plus-circle" }),
                  _c("span", { staticClass: "block-classic-description" }, [
                    _c("img", {
                      staticClass: "classic-logo img-fluid",
                      attrs: {
                        src:
                          "/etc.clientlibs/bclc/clientlibs/clientlib-base/resources/assets/CLASSIC.svg",
                        alt: "classic-logo",
                        "aria-hidden": "true"
                      }
                    }),
                    _vm._v(
                      "$" +
                        _vm._s(_vm.formatPrize(_vm.draw.jackpot)) +
                        " MILLION"
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.draw.bonusDraws > 0
              ? _c("div", { staticClass: "jackpot__bonus" }, [
                  _c(
                    "p",
                    { staticClass: "jackpot__prize-breakdown__navigation" },
                    [
                      _vm.game.isLottoMax()
                        ? _c("img", {
                            staticClass: "jackpot__maxmillion-logo",
                            attrs: {
                              alt: "maxmillion-logo",
                              src: _vm.logos.maxMillionLogo
                            }
                          })
                        : _c(
                            "label",
                            {
                              staticClass: "jackpot__pill",
                              class: "jackpot__pill_active_" + _vm.game.id
                            },
                            [_vm._v(" PLUS ")]
                          ),
                      _vm._v(" "),
                      _vm.game.isDailyGrand()
                        ? _c(
                            "span",
                            { staticClass: "jackpot__bonus-draw-text" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.draw.bonusDraws) +
                                  " " +
                                  _vm._s(_vm.game.bonusDraws) +
                                  "\n\t\t\t\t\t"
                              )
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "jackpot__bonus-draw-text" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.draw.bonusDraws) +
                                  " " +
                                  _vm._s(_vm.game.bonusDraws.toUpperCase()) +
                                  "\n\t\t\t\t\t"
                              )
                            ]
                          )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm.isLoading
        ? _c("div", { staticClass: "jackpot__loading" }, [_vm._m(1)])
        : _c("div", { staticClass: "jackpot__tba" }, [_vm._m(2)]),
      _vm._v(" "),
      _c("div", { staticClass: "jackpot__divider" }),
      _vm._v(" "),
      (!_vm.game.isLotto649() && _vm.draw.jackpot > 0) ||
      (_vm.game.isLotto649() && _vm.draw.six49GPJackpot > 0) ||
      _vm.isError ||
      _vm.isTodayWinningNumbersAvailable
        ? _c("jackpot-card-footer", {
            attrs: { game: _vm.game, draw: _vm.draw }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "jackpot__error-text" }, [
      _vm._v("\n\t\t\t\tAn error has occurred."),
      _c("br"),
      _vm._v("Refresh or try again later.\n\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "jackpot__loading-text loading" }, [
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "jackpot__amount" }, [
      _c("p", { staticClass: "jackpot__amount-text" }, [
        _c("span", { staticClass: "jackpot__amount-dollar" }),
        _vm._v(" "),
        _c("span", { staticClass: "jackpot__amount-number" }, [
          _vm._v("\n\t\t\t\t\t\tTBA\n\t\t\t\t\t")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }