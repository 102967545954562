var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.game
    ? _c("jackpot-card", {
        key: _vm.game.id + "-" + _vm.now.getDay(),
        attrs: { game: _vm.game, cardClickable: this.$attrs.cardClickable }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }