import { render, staticRenderFns } from "./JackpotCardContent.vue?vue&type=template&id=47fd05a4&"
import script from "./JackpotCardContent.vue?vue&type=script&lang=js&"
export * from "./JackpotCardContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/_jenkinssoftserv/remote/workspace/commonPipeline/DigitalEcosystem/AEM/bclccom-aem-web/bclc-Build/ui.apps/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47fd05a4')) {
      api.createRecord('47fd05a4', component.options)
    } else {
      api.reload('47fd05a4', component.options)
    }
    module.hot.accept("./JackpotCardContent.vue?vue&type=template&id=47fd05a4&", function () {
      api.rerender('47fd05a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/content/jcr_root/apps/bclc/clientlibs/clientlib-vue/js/components/LotteryJackpotCard/JackpotCardContent.vue"
export default component.exports