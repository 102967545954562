var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "swiper",
        {
          ref: "swiper",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOptions },
          on: { ready: _vm.handleSwiperLoaded }
        },
        [
          _vm._t("tiles"),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            on: {
              click: function($event) {
                return _vm.prev()
              }
            },
            slot: "button-prev"
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            on: {
              click: function($event) {
                return _vm.next()
              }
            },
            slot: "button-next"
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-scrollbar",
            attrs: { slot: "scrollbar" },
            slot: "scrollbar"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }