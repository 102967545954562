import Vue from 'vue';
import components from './components.js';
import libraries from './libraries.js';
import store from './store';

var apps = [];

function startApp() {

  components.forEach(cmp => Vue.component(cmp.name, cmp.component));

  const elements = document.querySelectorAll('[data-component]');
  ([]).forEach.call(elements, (el) => {
    el.dataset.initialized = 'true';
    apps.push(new Vue({
      el: el,
      store
    }));
  });
}

function destroyApp() {
  apps.forEach(function(vm) {
    if (vm != null) {
      vm.$destroy();
    }
  });
  store.commit('resetState');
}

document.addEventListener("DOMContentLoaded", startApp);
// refresh App instance after edit in AEM
window.addEventListener('message', (e) => {
  if (e.data === 'edit') {
    destroyApp();
    startApp();
  }
}, false);
