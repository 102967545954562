<template>
	<div
		class="jackpot__logo"
		:class="'jackpot__logo_' + game.id"
	>
		<img
			:alt="game.name"
			class="jackpot__logo-image"
			:class="'jackpot__logo-image__' + game.id"
			:src="game.getLogoUrl()"
		>
	</div>
</template>
<script>
	export default {
		name: 'JackpotCardImage',
		components: {},
		props: {
			game: {
				type: Object,
				required: true
			}
		}
	};
</script>