<template>
	<div
		class="jackpot"
		:class="'jackpot_' + game.id + ' ' + clickableClassName"
		@click="trigger"
	>
		<jackpot-card-header
			:game="game"
			:draw-date="drawDate"
			:draw="draw"
		/>

		<jackpot-card-body
			:game="game"
			:day="day"
			:draw-date="drawDate"
			:draw="draw"
		/>
	</div>
</template>
<script>
	import JackpotCardHeader from './JackpotCardHeader.vue';
	import JackpotCardBody from './JackpotCardBody.vue';
	import LotteryResources from '../../library/lotto-platform/LotteryResources.js';

	export default {
		name: 'JackpotCard',
		components: {
			JackpotCardHeader,
			JackpotCardBody,
		},
		props: {
			/**
			 *	Jackpot's game data
			 */
			game: {
				type: Object,
				required: true
			},
			cardClickable: {
				type: Boolean,
				required: true
			},
		},
		data() {
			const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

			return {
				days: daysOfWeek,
				draw: {},
				day: "",
				drawDate: ""
			};
		},
		computed: {
			isLoading() {
				return this.$store.state.JackpotStore.loadingTracker && Array.from(this.$store.state.JackpotStore.loading);
			},
			isError() {
				return this.$store.state.JackpotStore.errored;
			},
			isLoadingDraw() {
				return Object.keys(this.draw).length === 0 && this.draw.constructor === Object
					&& this.day === "" && this.drawDate === ""
			},
			isDateInDrawBreakThreshold() {
				return LotteryResources.isInDrawBreak(this.$store.state.JackpotStore.serverDateTime, this.game);
			},
			isTodayWinningNumbersAvailable() {
				return this.$store.getters.isTodayWinningNumbersAvailable(this.game.id);
			},
			clickableClassName() {
				let className = "clickable";
				if (!this.cardClickable
					|| (this.isLoadingDraw && !this.isError)
					|| (this.isDateInDrawBreakThreshold && !this.isTodayWinningNumbersAvailable)
					|| ((!this.game.isLotto649() && this.draw.jackpot === 0) || (this.game.isLotto649() && this.draw.six49GPJackpot === 0))) {
					className = "";
				}
				return className;
			}
		},
		watch: {
			isLoading(newValue, oldValue) {
				//console.log(`Loading updating from ${oldValue} to ${newValue}`);
				this.processDrawData(this.$store.getters.getDraw(this.game.id));
			}
		},
		created() {
			this.$store.dispatch('initializeJackpotStore');
		},
		methods: {
			processDrawData(result) {
				let monthNames = [
					'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
				];

				if (result && result.jackpot !== undefined) {
					let drawDate = new Date();
					let now = this.$store.state.JackpotStore.serverDateTime;
					if (result.nextDrawDate) {
						// handle case except PHEP where there is no nextDrawDate
						//drawDate = new Date(result.nextDrawDate);
						drawDate = LotteryResources.parseDrawDate(result.nextDrawDate);
						this.drawDate = drawDate;
						this.day = drawDate.getDay();
					}
					let selectDrawDate = monthNames[drawDate.getMonth()] + ' ' + drawDate.getDate() + ', ' + drawDate.getFullYear();

					this.draw = {
						id: this.game.id,
						name: this.game.name,
						day: this.days[this.day],
						date: selectDrawDate,
						jackpot: result.jackpot,
						six49GPJackpot: result.six49GPJackpot,
						six49GPPrize: result.six49GPPrize,
						bonusDraws: result.bonusDraws ? result.bonusDraws : this.draw.bonusDraws,
						six49AdditionalGPs: result.six49AdditionalGPs ? result.six49AdditionalGPs : this.draw.six49AdditionalGPs,
						six49GPs: result.six49GPs ? result.six49GPs : this.draw.six49GPs,
						six49GPWhiteBallsRemaining: result.six49GPWhiteBallsRemaining ? result.six49GPWhiteBallsRemaining : 0,
						annuityDetails: result.annuityDetails ? result.annuityDetails : {},
						error: false
					};
				}
			},
			trigger () {
				if (this.cardClickable && this.isError) {
					this.refresh();
				}
				else if (this.cardClickable && this.isDateInDrawBreakThreshold && this.isTodayWinningNumbersAvailable) {
					this.navigateToWinningNumbers();
				} else if (!this.isDateInDrawBreakThreshold) {
					if (this.cardClickable
						&& ((!this.game.isLotto649() && this.draw.jackpot > 0) || (this.game.isLotto649() && this.draw.six49GPJackpot > 0))) {
						this.navigateToBuyTickets();
					}
				}
			},
			navigateToBuyTickets() {
				let url = this.game.getBuyTicketURL();
				if (!url) {
					url = this.game.getProductBuyTicketsUrl();
				}
				//window.location.href = url;
				window.open(url, "_self");
			},
			refresh() {
				this.$store.dispatch('reload');
			},
			navigateToWinningNumbers() {
				let url = this.game.getWinningNumbersURL();
				if (!url) {
					url = this.game.getProductWinningNumbersUrl();
				}
				//window.location.href = url;
				window.open(url, "_self");
			}
		}
	};
</script>