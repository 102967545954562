<template>
	<div>
		<swiper class="swiper" ref="swiper" :options="swiperOptions" @ready="handleSwiperLoaded">
				
			<slot name="tiles"></slot>
			<!-- If we need navigation buttons -->
			<div class="swiper-button-prev" slot="button-prev" @click="prev()"></div>
			<div class="swiper-button-next" slot="button-next" @click="next()"></div>

			<!-- If we need scrollbar -->
			<div class="swiper-scrollbar" slot="scrollbar"></div>
		</swiper>
	</div>
</template>
<script>
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	
	export default {
		name: 'GenericContainer',
		components: {
			Swiper,
			SwiperSlide
		},
		directives: {
			swiper: directive
		},
		data() {
			return {
				swiperOptions: {
					slidesPerView: 'auto',
					freeMode: true,
					watchSlidesProgress: true,
					watchSlidesVisibility: true,
					grabCursor: true,
					spaceBetween: 24,
					breakpoints: {
						// when window width is >= 1200px
						1200: {
						spaceBetween: 32
						}
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					scrollbar: {
						el: '.swiper-scrollbar',
						draggable: true,
					},
				},
			};
		},
		computed: {
			swiper() {
				return this.$refs.swiper.$swiper
			}
		},mounted() {			
			this.sentMountedEvent();
		},		
		methods: {	
			sentMountedEvent() {
				//trigger an update on the page body.
				let event = new CustomEvent("VueJSMounted", {
					detail: {
						message: "Mounted VueJs",
						time: new Date(),
					},
					bubbles: true,
					cancelable: true
				});
				document.body.dispatchEvent(event);
			},		
			prev() {
				this.$refs.swiper.$swiper.slidePrev(100);
			},
			next() {
				this.$refs.swiper.$swiper.slideNext(100);
			},

			handleSwiperLoaded(swiper) {
				//console.log('Swiper was mounted!', swiper);
				if (swiper.slides) {
					//console.log('Swiper slides', swiper.slides);
					swiper.slides.each(function(index) {
						if ($(this).hasClass("tile")) {
							$(this).attr("target", index+1);
						}
						else {
							$(this).children(".tile").attr("target", index+1);
						}
					});
				}
			}
		}
	};
</script>