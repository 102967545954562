var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "jackpot",
      class: "jackpot_" + _vm.game.id + " " + _vm.clickableClassName,
      on: { click: _vm.trigger }
    },
    [
      _c("jackpot-card-header", {
        attrs: { game: _vm.game, "draw-date": _vm.drawDate, draw: _vm.draw }
      }),
      _vm._v(" "),
      _c("jackpot-card-body", {
        attrs: {
          game: _vm.game,
          day: _vm.day,
          "draw-date": _vm.drawDate,
          draw: _vm.draw
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }