<template>
    <jackpot-card
		v-if="game"
		:key="game.id + `-${now.getDay()}`"
		:game="game"
		:cardClickable="this.$attrs.cardClickable"
	/>
</template>
<script>
	import JackpotCard from './LotteryJackpotCard/JackpotCard.vue';
	import GameProvider from '../library/lotto-platform/GameProvider.js';
	import LotteryResources from '../library/lotto-platform/LotteryResources.js';

	export default {
		name: 'LotteryJackpotCard',
		components: {
			JackpotCard:JackpotCard
		},
		data() {
			return {
				now: this.dateData(),
				game: this.gameData(this.$attrs.gameid)
			};
		},
		methods: {
			dateData() {
                return new Date();
			},

			gameData(gameId) {
				let game = GameProvider.getGameById(gameId);
				game.setBuyTicketLabel(this.$attrs.ctalabel);
				game.setBuyTicketURL(this.$attrs.ctaurl);
				game.setWinningNumbersURL(this.$attrs.winningnumbersurl);
                return game;
			}
		}
	};
</script>