<template>
	<div
		class="jackpot__body"
		:class="'jackpot__body_' + game.id"
	>
		<jackpot-card-image
			:game="game"
		/>

		<jackpot-card-content
			:game="game"
			:day="day"
			:draw-date="drawDate"
			:draw="draw"
		/>
	</div>
</template>
<script>
	import JackpotCardImage from "./JackpotCardImage.vue";
	import JackpotCardContent from "./JackpotCardContent.vue";

	export default {
		name: 'JackpotCardBody',
		components: {
			JackpotCardImage,
			JackpotCardContent
		},
		props: {
			game: {
				type: Object,
				required: true
			},
			day: {
				//type: Number,
				required: true
			},
			drawDate: {
				//type: Date,
				required: true
			},
			draw: {
				type: Object,
				required: true
			}
		}
	};
</script>