var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jackpot__header" }, [
    _vm.game.isPacificHEPoker()
      ? _c("p", { staticClass: "jackpot__day" }, [
          _vm._v("\n\t\tEvery 6:00 minutes\n\t")
        ])
      : _vm.isServerDateAvailable && !_vm.isLoadingDraw
      ? _c("p", { staticClass: "jackpot__day" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.header) + "\n\t")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }